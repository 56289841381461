document.addEventListener("DOMContentLoaded", function() {

    let written = false;
    let pageable = null;
    var gameInstance = null;
    var fadeTarget = null;
    var fadeEffect = null;
    var timeoutControles = null;

    init();

    function init() {

        let liensUser = document.querySelectorAll('.menu1 a');
        if(liensUser.length === 1){
            let iconeUser = document.querySelector('.icone1');
            let iconeUser2 = document.querySelector('.icone2');
            iconeUser2.href = liensUser[0].href;
            iconeUser2.style.display = "block";
            iconeUser.style.display = "none";
        }

        var tabLinks = document.querySelectorAll('.tablink');

        for(let i = 0 ; i < tabLinks.length; i++) {
            var tab = tabLinks[i];
            tab.addEventListener('click', ouvreSection);
            if(tab.classList.contains("tab1")){
                tab.className += " actif";
            }
        }

        var flechePre = document.querySelectorAll('.pre');
        for(let i = 0 ; i < flechePre.length; i++) {
            var pre = flechePre[i];
            pre.addEventListener('click', ouvrePreSection);
        }

        var flecheSui = document.querySelectorAll('.sui');
        for(let i = 0 ; i < flecheSui.length; i++) {
            var sui = flecheSui[i];
            sui.addEventListener('click', ouvreSuiSection);
        }

        var flechePreD = document.querySelectorAll('.preD');
        for(let i = 0 ; i < flechePreD.length; i++) {
            var preD = flechePreD[i];
            preD.addEventListener('click', ouvrePreSectionD);
        }

        var flecheSuiD = document.querySelectorAll('.suiD');
        for(let i = 0 ; i < flecheSuiD.length; i++) {
            var suiD = flecheSuiD[i];
            suiD.addEventListener('click', ouvreSuiSectionD);
        }

        var flechePreR = document.querySelectorAll('.preR');
        for(let i = 0 ; i < flechePreR.length; i++) {
            var preR = flechePreR[i];
            preR.addEventListener('click', ouvrePreSectionR);
        }

        var flecheSuiR = document.querySelectorAll('.suiR');
        for(let i = 0 ; i < flecheSuiR.length; i++) {
            var suiR = flecheSuiR[i];
            suiR.addEventListener('click', ouvreSuiSectionR);
        }

        var section = document.querySelectorAll('.centre');
        for(let i = 0 ; i < section.length; i++) {
            var sect = section[i];
            if (sect.classList.contains("1")) {
            }
            else if(sect.classList.contains("equipe1")){
                sect.style.display = 'block'
            }
            else {
                sect.style.display = 'none'
            }
        }

        var tabLinks2 = document.querySelectorAll('.tablink2');

        for(let i = 0 ; i < tabLinks2.length; i++) {
            var tab2 = tabLinks2[i];

            if(tab2.classList.contains("tab1")){
                tab2.className += " actif";
            }
            tab2.addEventListener('click', ouvreSection2);
        }

        var imageGalerie = document.querySelectorAll('.une-image');

        for(let i = 0 ; i < imageGalerie.length; i++) {
            var image = imageGalerie[i];

            image.addEventListener('click', ouvreModalGalerie);
        }

        window.addEventListener('resize', fVerifierLargeur);
        fVerifierLargeur();

        // PAGE DES COURS - s'il y a plus que 8 cours dans la liste
        if(document.querySelectorAll(".cours").length > 0){
            var listeCours = document.querySelectorAll("#listeCours a");

            // quand il y a plus que 8 cours, ajoute une classe
            if(listeCours.length>8){
                for(let i = 0; i<listeCours.length; i++){
                    listeCours[i].className = "plusCours"
                }
            }

        }
    }

    function fVerifierLargeur() {

        const mqW = window.matchMedia("(max-width: 900px)");
        const mqH = window.matchMedia("(max-height: 880px)");

        if (mqW.matches || mqH.matches) {
            if (written) {
                pageable.destroy();
                written = false;
            }
        } else {
            if (written) {
            } else {

                pageable = new Pageable("#container", {
                    childSelector: "[data-anchor]", // CSS3 selector string for the pages
                    anchors: [], // define the page anchors
                    pips: true, // display the pips
                    animation: 250, // the duration in ms of the scroll animation
                    delay: 0, // the delay in ms before the scroll animation starts
                    throttle: 50, // the interval in ms that the resize callback is fired
                    orientation: "vertical", // or horizontal
                    swipeThreshold: 50, // swipe / mouse drag distance (px) before firing the page change event
                    freeScroll: false, // allow manual scrolling when dragging instead of automatically moving to next page
                    navPrevEl: false, // define an element to use to scroll to the previous page (CSS3 selector string or Element reference)
                    navNextEl: false, // define an element to use to scroll to the next page (CSS3 selector string or Element reference)
                    infinite: false, // enable infinite scrolling (from 0.4.0)
                    events: {
                        wheel: true, // enable / disable mousewheel scrolling
                        mouse: false, // enable / disable mouse drag scrolling
                        touch: true, // enable / disable touch / swipe scrolling
                        keydown: true, // enable / disable keyboard navigation
                    },
                    easing: function (currentTime, startPos, endPos, interval) {
                        // the easing function used for the scroll animation
                        return -endPos * (currentTime /= interval) * (currentTime - 2) + startPos;
                    },
                    onInit: function (data) {
                        // do something when the instance is ready
                        fDetecterCouleur(data);

                        let nombrePoints = document.querySelector(".pg-pips ul").getElementsByTagName("li").length;
                        // console.log(nombrePoints);

                        for (let i = 1;i < nombrePoints + 1;i++) {
                            // console.log(i);

                            let sectionCiblee = document.getElementById("page-" + i);
                            document.querySelector(".pg-pips ul li:nth-child(" + (i) + ") a span").innerHTML = sectionCiblee.dataset.title;
                        }
                    },
                    onUpdate: function () {
                        // do something when the instance updates
                    },
                    onBeforeStart: function () {
                        // do something before scrolling begins
                    },
                    onStart: function () {
                        // do something when scrolling begins
                    },
                    onScroll: function () {
                        // do something during scroll
                    },
                    onFinish: function (data) {
                        // do something when scrolling ends
                        fDetecterCouleur(data);

                        let section = document.querySelector(".section" + (data.index + 1));

                        if(section.classList.contains("visite")) {
                            //console.log("visite");
                            detectWebGLContext();

                            function detectWebGLContext () {
                                // Create canvas element. The canvas is not added to the
                                // document itself, so it is never displayed in the
                                // browser window.
                                var canvas = document.createElement("canvas");
                                // Get WebGLRenderingContext from canvas element.
                                var gl = canvas.getContext("webgl")
                                    || canvas.getContext("experimental-webgl");
                                // Report the result.
                                if (gl && gl instanceof WebGLRenderingContext) {

                                    //WebGL est supporté
                                    //console.log('webgl supporté');

                                    if (mqW.matches || mqH.matches) {
                                        document.querySelector('.erreurVisite').innerHTML = document.querySelector('.erreurResolution').innerHTML;
                                    } else {
                                        msieversion();
                                    }
                                } else {

                                    //WebGL n'est pas supporté
                                    //console.log('webgl non supporté');

                                    //console.log(document.querySelector('.erreurVisite'));
                                    document.querySelector('.erreurVisite').innerHTML = document.querySelector('.erreurWebGL').innerHTML;
                                    document.querySelector('.erreurVisite').style.display = "block";
                                }
                            }

                            function msieversion() {

                                var ua = window.navigator.userAgent;
                                var msie = ua.indexOf("MSIE ");

                                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
                                {
                                    // alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
                                    //console.log('internet explorer');

                                    //console.log(document.querySelector('.erreurVisite'));
                                    document.querySelector('.erreurVisite').innerHTML = document.querySelector('.erreurIE').innerHTML;
                                    document.querySelector('.erreurVisite').style.display = "block";
                                }
                                else  // If another browser, return 0
                                {
                                    //console.log("instantiation started");
                                    section.innerHTML = '';
                                    section.innerHTML += document.querySelector('.controlesContainer').innerHTML;
                                    section.innerHTML += '<div id="unityContainer"></div>';
                                    var chemin = document.querySelector('.cheminUnity').innerHTML;
                                    gameInstance = UnityLoader.instantiate("unityContainer", chemin+"/Build/build8.json", {onProgress: UnityProgress});
                                    //console.log("instantiation complete");

                                    timeoutControles = setTimeout(function fadeOutEffect() {
                                        fadeTarget = document.querySelector('.visite .controles');
                                        fadeEffect = setInterval(function () {
                                            if (!fadeTarget.style.opacity) {
                                                fadeTarget.style.opacity = 1;
                                            }
                                            if (fadeTarget.style.opacity > 0) {
                                                fadeTarget.style.opacity -= 0.05;
                                            } else {
                                                section.removeChild(document.querySelector('.controles'));
                                                clearInterval(fadeEffect);
                                            }
                                        }, 25);
                                    },10000);

                                    // setTimeout(function supprControles() {
                                    //     section.removeChild(document.querySelector('.controles'));
                                    // }, 10000);

                                }

                                return false;
                            }
                        } else {
                            //console.log("pas visite");
                            clearTimeout(timeoutControles);
                            timeoutControles = null;

                            if(gameInstance !== null) {
                                gameInstance.Quit();
                                gameInstance = null;
                            }

                            let page = document.querySelector("body>div");

                            if(page.classList.contains("atelier")) {
                                //document.querySelector(".visite").appendChild(document.querySelector('#container>.erreurVisite'));
                            }
                        }
                    },
                });

                written = true;
            }
        }
    }

    function fDetecterCouleur(data) {

        let idSection = "page-" + (data.index + 1);
        let sectionCourante = document.getElementById(idSection);
        if (sectionCourante.classList.contains("pale")) {
            document.querySelector(".pg-pips ul").classList.remove('points-fonce');
            document.querySelector(".pg-pips ul").classList.add('points-pale');
        } else if (sectionCourante.classList.contains("fonce")) {
            document.querySelector(".pg-pips ul").classList.remove('points-pale');
            document.querySelector(".pg-pips ul").classList.add('points-fonce');
        }
    }

    function ouvreSection(evt) {
        let nomSection = evt.currentTarget.dataset.id;

        let navClic = evt.currentTarget.parentNode.parentNode.parentNode.parentNode;
        let parentNavClic = evt.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode;

        let i, x, tablinks;

        x = navClic.getElementsByClassName("tab-section");
        for (i = 0; i < x.length; i++) {
            x[i].classList.remove("displayOn");
        }

        tablinks = navClic.getElementsByClassName("tablink");
        for (i = 0; i < x.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" actif", "");
        }

        document.getElementById(nomSection).classList.add("displayOn");
        // parentNavClic.querySelector('section>img').src = navClic.querySelector('.img'+nomSection).src;
        parentNavClic.querySelector('section>img').src = navClic.querySelector('#'+nomSection+' img').src;
        parentNavClic.querySelector('section>img').alt = navClic.querySelector('#'+nomSection+' img').alt;
        evt.currentTarget.className += " actif";
    }

    // flèche précédent
    function ouvrePreSection(evt){
        let section = evt.currentTarget.parentNode.parentNode;
        let navActive = section.getElementsByClassName('actif');
        let nav = section.getElementsByClassName('tablink');
        let nomSectionActive = navActive[0].dataset.id;
        let nomSection;
        let texte = section.getElementsByClassName("centre");

        for(let y = 0; y < nav.length; y++){
            nomSection = nav[y].dataset.id;
            if(nomSection === nomSectionActive){
                let i, x, tablinks, sectionPre, nomSectionPre;

                x = section.getElementsByClassName("tab-section");
                for (i = 0; i < x.length; i++) {
                    x[i].classList.remove("displayOn");
                }
                tablinks = section.getElementsByClassName("tablink");
                for (i = 0; i < x.length; i++) {
                    tablinks[i].className = tablinks[i].className.replace(" actif", "");
                }

                if(y === 0){
                   sectionPre = nav.length - 1;
                   nomSectionPre = nav[sectionPre].dataset.id;
                   document.getElementById(nomSectionPre).classList.add("displayOn");
                   nav[sectionPre].className += " actif";

                   section.querySelector('section>img').src = section.querySelector('#'+nomSectionPre+' img').src;
                   section.querySelector('section>img').alt = section.querySelector('#'+nomSectionPre+' img').alt;

                } else{
                    sectionPre = y-1;
                    nomSectionPre = nav[sectionPre].dataset.id;
                    document.getElementById(nomSectionPre).classList.add("displayOn");
                    nav[sectionPre].className += " actif";

                    section.querySelector('section>img').src = section.querySelector('#'+nomSectionPre+' img').src;
                    section.querySelector('section>img').alt = section.querySelector('#'+nomSectionPre+' img').alt;
                }

            }
        }
    }

    // flèche suivant
    function ouvreSuiSection(evt){
        let section = evt.currentTarget.parentNode.parentNode;
        let navActive = section.getElementsByClassName('actif');
        let nav = section.getElementsByClassName('tablink');
        let nomSectionActive = navActive[0].dataset.id;
        let nomSection;

        for(let y = 0; y < nav.length; y++){
            nomSection = nav[y].dataset.id;
            if(nomSection === nomSectionActive){
                let i, x, tablinks, sectionSui, nomSectionSui;

                x = section.getElementsByClassName("tab-section");
                for (i = 0; i < x.length; i++) {
                    x[i].classList.remove("displayOn");
                }
                tablinks = section.getElementsByClassName("tablink");
                for (i = 0; i < x.length; i++) {
                    tablinks[i].className = tablinks[i].className.replace(" actif", "");
                }

                if(y === nav.length - 1){
                    sectionSui = 0;
                    nomSectionSui = nav[sectionSui].dataset.id;
                    document.getElementById(nomSectionSui).classList.add("displayOn");
                    nav[sectionSui].className += " actif";

                    section.querySelector('section>img').src = section.querySelector('#'+nomSectionSui+' img').src;
                    section.querySelector('section>img').alt = section.querySelector('#'+nomSectionSui+' img').alt;
                } else{
                    sectionSui = y+1;
                    nomSectionSui = nav[sectionSui].dataset.id;
                    document.getElementById(nomSectionSui).classList.add("displayOn");
                    nav[sectionSui].className += " actif";

                    section.querySelector('section>img').src = section.querySelector('#'+nomSectionSui+' img').src;
                    section.querySelector('section>img').alt = section.querySelector('#'+nomSectionSui+' img').alt;
                }

            }
        }
    }


    // desktop
    // flèche précédent
    function ouvrePreSectionD(evt){
        let section = evt.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode;
        let navActive = section.getElementsByClassName('actif');
        let nav = section.getElementsByClassName('tablink');
        let nomSectionActive = navActive[0].dataset.id;
        let nomSection;
        let texte = section.getElementsByClassName("centre");

        for(let y = 0; y < nav.length; y++){
            nomSection = nav[y].dataset.id;
            if(nomSection === nomSectionActive){
                let i, x, tablinks, sectionPre, nomSectionPre;

                x = section.getElementsByClassName("tab-section");
                for (i = 0; i < x.length; i++) {
                    x[i].classList.remove("displayOn");
                }
                tablinks = section.getElementsByClassName("tablink");
                for (i = 0; i < x.length; i++) {
                    tablinks[i].className = tablinks[i].className.replace(" actif", "");
                }

                if(y === 0){
                    sectionPre = nav.length - 1;
                    nomSectionPre = nav[sectionPre].dataset.id;
                    document.getElementById(nomSectionPre).classList.add("displayOn");
                    nav[sectionPre].className += " actif";

                    section.querySelector('section>img').src = section.querySelector('#'+nomSectionPre+' img').src;
                    section.querySelector('section>img').alt = section.querySelector('#'+nomSectionPre+' img').alt;

                } else{
                    sectionPre = y-1;
                    nomSectionPre = nav[sectionPre].dataset.id;
                    document.getElementById(nomSectionPre).classList.add("displayOn");
                    nav[sectionPre].className += " actif";

                    section.querySelector('section>img').src = section.querySelector('#'+nomSectionPre+' img').src;
                    section.querySelector('section>img').alt = section.querySelector('#'+nomSectionPre+' img').alt;
                }

            }
        }
    }

    // flèche suivant
    function ouvreSuiSectionD(evt){
        let section = evt.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode;
        let navActive = section.getElementsByClassName('actif');
        let nav = section.getElementsByClassName('tablink');
        let nomSectionActive = navActive[0].dataset.id;
        let nomSection;

        for(let y = 0; y < nav.length; y++){
            nomSection = nav[y].dataset.id;
            if(nomSection === nomSectionActive){
                let i, x, tablinks, sectionSui, nomSectionSui;

                x = section.getElementsByClassName("tab-section");
                for (i = 0; i < x.length; i++) {
                    x[i].classList.remove("displayOn");
                }
                tablinks = section.getElementsByClassName("tablink");
                for (i = 0; i < x.length; i++) {
                    tablinks[i].className = tablinks[i].className.replace(" actif", "");
                }

                if(y === nav.length - 1){
                    sectionSui = 0;
                    nomSectionSui = nav[sectionSui].dataset.id;
                    document.getElementById(nomSectionSui).classList.add("displayOn");
                    nav[sectionSui].className += " actif";

                    section.querySelector('section>img').src = section.querySelector('#'+nomSectionSui+' img').src;
                    section.querySelector('section>img').alt = section.querySelector('#'+nomSectionSui+' img').alt;
                } else{
                    sectionSui = y+1;
                    nomSectionSui = nav[sectionSui].dataset.id;
                    document.getElementById(nomSectionSui).classList.add("displayOn");
                    nav[sectionSui].className += " actif";

                    section.querySelector('section>img').src = section.querySelector('#'+nomSectionSui+' img').src;
                    section.querySelector('section>img').alt = section.querySelector('#'+nomSectionSui+' img').alt;
                }

            }
        }
    }

    // RÈGLES
    // flèche précédent
    function ouvrePreSectionR(evt){
        let section = evt.currentTarget.parentNode.parentNode.parentNode;
        let navActive = section.getElementsByClassName('actif');
        let nav = section.getElementsByClassName('tablink2');
        let nomSectionActive = navActive[0].dataset.id;
        let nomSection;
        let texte = section.getElementsByClassName("centre");

        for(let y = 0; y < nav.length; y++){
            nomSection = nav[y].dataset.id;
            if(nomSection === nomSectionActive){
                let i, x, tablinks, sectionPre, nomSectionPre;

                x = section.getElementsByClassName("tab-section");
                for (i = 0; i < x.length; i++) {
                    x[i].style.display = "none";
                }
                tablinks = section.getElementsByClassName("tablink2");
                for (i = 0; i < x.length; i++) {
                    tablinks[i].className = tablinks[i].className.replace(" actif", "");
                }

                if(y === 0){
                    sectionPre = nav.length - 1;
                    nomSectionPre = nav[sectionPre].dataset.id;
                    document.getElementById(nomSectionPre).style.display = "flex";
                    nav[sectionPre].className += " actif";

                } else{
                    sectionPre = y-1;
                    nomSectionPre = nav[sectionPre].dataset.id;
                    document.getElementById(nomSectionPre).style.display = "flex";
                    nav[sectionPre].className += " actif";}

            }
        }
    }

    // flèche suivant
    function ouvreSuiSectionR(evt){
        let section = evt.currentTarget.parentNode.parentNode.parentNode;
        let navActive = section.getElementsByClassName('actif');
        let nav = section.getElementsByClassName('tablink2');
        let nomSectionActive = navActive[0].dataset.id;
        let nomSection;

        for(let y = 0; y < nav.length; y++){
            nomSection = nav[y].dataset.id;
            if(nomSection === nomSectionActive){
                let i, x, tablinks, sectionSui, nomSectionSui;

                x = section.getElementsByClassName("tab-section");
                for (i = 0; i < x.length; i++) {
                    x[i].style.display = "none";
                }
                tablinks = section.getElementsByClassName("tablink2");
                for (i = 0; i < x.length; i++) {
                    tablinks[i].className = tablinks[i].className.replace(" actif", "");
                }

                if(y === nav.length - 1){
                    sectionSui = 0;
                    nomSectionSui = nav[sectionSui].dataset.id;
                    document.getElementById(nomSectionSui).style.display = "flex";
                    nav[sectionSui].className += " actif";
                } else{
                    sectionSui = y+1;
                    nomSectionSui = nav[sectionSui].dataset.id;
                    document.getElementById(nomSectionSui).style.display = "flex";
                    nav[sectionSui].className += " actif";
                }

            }
        }
    }
    // ouvre section 2 (page atelier, section des règles)
    function ouvreSection2(evt) {
        let nomSection = evt.currentTarget.dataset.id;
        let navClic = evt.currentTarget.parentNode.parentNode.parentNode;
        let parentNavClic = evt.currentTarget.parentNode.parentNode.parentNode.parentNode;

        let i, x, tablinks;

        x = navClic.getElementsByClassName("tab-section");
        for (i = 0; i < x.length; i++) {
            x[i].style.display = "none";
        }

        tablinks = navClic.getElementsByClassName("tablink2");
        for (i = 0; i < x.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" actif", "");
        }

        document.getElementById(nomSection).style.display = "flex";

        evt.currentTarget.className += " actif";
    }



    // MODAL

    var modal = document.getElementById("modal");
    var modalLinks = modal.querySelectorAll('a');

    var openModal = document.getElementById("open-modal");
    var closeModal = document.getElementsByClassName("close-modal")[0];

    openModal.onclick = function() {
        modal.style.transform = "translateX(0%)";
    };

    closeModal.onclick = function() {
        modal.style.transform = "translateX(100%)";
    };

    for(var i = 0; i< modalLinks.length ; i++) {
        var link = modalLinks[i];
        link.addEventListener('click', fermeModal);
    }

    function fermeModal(e) {
        modal.style.transform = "translateX(100%)";
    }

    // home et user icones haut

    let lastScrollTop=0;
    let icones;
    icones = document.getElementsByClassName('haut-logo');

    if(window.innerWidth <= 400){
        window.addEventListener("scroll",function(){
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop > lastScrollTop) {
                icones[0].style.display = "none";
                if(document.getElementById("fleche-retour")){
                    retour = document.getElementById('fleche-retour');
                    retour.style.top = 30+'px';
                }
                else if(document.getElementById("fleche-retour2")){
                    retour2 = document.getElementById('fleche-retour2');
                    retour2.style.top = 30+'px';
                }
            } else{
                icones[0].style.display = "block";
                if(document.getElementById("fleche-retour")){
                    icones[0].style.display = "none";
                }
                else if(document.getElementById("fleche-retour2")){
                    icones[0].style.display = "none";
                }
            }
            lastScrollTop = scrollTop;
        })
    } else if(document.getElementById("fleche-retour")){
        retour = document.getElementById('fleche-retour');
        retour.style.top = 120+'px';
    }



    // modal galerie

    function ouvreModalGalerie(evt){
        let urlImage = evt.currentTarget.dataset.image;
        let h3 = evt.currentTarget.querySelector("h3").textContent;
        let h4 = evt.currentTarget.querySelector("h4").textContent;
        let annee = evt.currentTarget.querySelector(".anneeData").textContent;
        let materiaux = evt.currentTarget.querySelector(".materiauxData").textContent;
        let portfolio = evt.currentTarget.querySelector(".info").dataset.portfolio;
        var modalGalerie = document.getElementById("modal-galerie");
        modalGalerie.style.display = "flex";
        document.querySelector("html").style.overflow = "hidden";

        var uneImage = document.querySelector(".imageOeuvre");
        uneImage.src = urlImage;
        var unNom = document.querySelector(".nom-oeuvre");
        unNom.innerHTML = h3;
        uneImage.alt = h3;
        var unAuteur = document.querySelector(".nom-auteur");
        if (portfolio === undefined){
            unAuteur.innerHTML = h4;
        } else{
            unAuteur.innerHTML = h4 + "<a href='"+portfolio+"' target='_blank'><p>Voir le portfolio&nbsp;<i class=\"fas fa-external-link-alt\"></i></p></a>";
        }
        var uneAnnee = document.querySelector(".nom-annee");
        if (annee === ''){
            uneAnnee.parentNode.style.display = 'none';
        } else{
            uneAnnee.parentNode.style.display = 'block';
            uneAnnee.innerHTML = annee;
        }
        var unMateriau = document.querySelector(".nom-materiauxUtilises");
        if (materiaux === ''){
            unMateriau.parentNode.style.display = 'none';
        } else{
            unMateriau.parentNode.style.display = 'block';
            unMateriau.innerHTML = materiaux;
        }

        var closeModalGalerie = document.getElementById("close-modal-galerie");


        closeModalGalerie.onclick = function() {
            modalGalerie.style.display = "none";
            document.querySelector("html").style.overflow = "auto";
        };

        window.onclick = function (evt) {
            if(evt.target === modalGalerie) {
                modalGalerie.style.display = "none";
                document.querySelector("html").style.overflow = "auto";
            }
        }
    }

    //modal Partenaires

    var modalPartenaires = document.getElementById("modal-partenaires");
    var btOpenPartenaires = document.getElementById("open-modal-partenaires");
    var btClosePartneaires = document.getElementById("close-modal-partenaires");
    var containerPartenaires = document.getElementById("container-partenaires");

    btOpenPartenaires.onclick = function() {
        modalPartenaires.style.display = "flex";
        document.querySelector("html").style.overflow = "hidden";
    };

    btClosePartneaires.onclick = function () {
        modalPartenaires.style.display = "none";
        document.querySelector("html").style.overflow = "auto";
    };

    modalPartenaires.onclick = function () {
        modalPartenaires.style.display = "none";
        document.querySelector("html").style.overflow = "auto";
    };

    containerPartenaires.onclick = function () {
        modalPartenaires.style.display = "none";
        document.querySelector("html").style.overflow = "auto";
    };

    //SCROLLMAGIC

    var ctrl = new ScrollMagic.Controller();
    var sections = document.querySelectorAll('section');

    for (let i = 0; i < sections.length; i++) {
        var titreAnim = sections[i].querySelectorAll(".titreAnim");
        var textAnim = sections[i].querySelectorAll(".textAnim");
        var tl = new TimelineMax();

        tl.from(titreAnim, 0.25, { scaleX: 0});
        tl.from(titreAnim, 0.45, { yPercent: 100, ease: Expo.easeOut });
        tl.from(textAnim, 0.45, { opacity: 0, yPercent: -20, ease: Expo.easeOut}, 0.6);

        // let img = document.getElementsByClassName('imageSection');
        //
        // let chk = () => {
        //     let containsImg = document.getElementsByClassName('section').contains(img);
        // };
        //
        // if(chk){
        //     tl.from(img, { opacity: 0, ease: Back.easeOut})
        // }

        new ScrollMagic.Scene({
            triggerElement: sections[i],
            triggerHook: 0.4
        })
            .setTween(tl)
            .addTo(ctrl);
    }
});